import React from 'react';
import { ParagraphLinksFragmentFragment } from '../../../generated/types';
import { Button, Flex } from '@chakra-ui/react';
import Wrapper from '../Wrapper';
import Container from '../../Layout/Container';
import Link from 'next/link';
import addFilterToSearchPageUrl from '../../../utils/addFilterToSearchPageUrl';

export interface LinksProps {
  data: ParagraphLinksFragmentFragment;
}

const Links: React.FC<LinksProps> = ({ data }) => {
  return (
    <Wrapper className="ParagraphLinks">
      <Container className="ParagraphContainer">
        <Flex
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          mt={-2}
        >
          {data.links.map((linkParagraph) => {
            const isExternal = linkParagraph.link.url.indexOf('https://') === 0;

            return (
              <Link
                key={linkParagraph.link.url}
                href={
                  isExternal
                    ? linkParagraph.link.url
                    : addFilterToSearchPageUrl(
                        linkParagraph.link.url,
                        linkParagraph.countries,
                        linkParagraph.regions
                      )
                }
                passHref
              >
                <Button
                  mt={2}
                  mr={2}
                  key={linkParagraph.link.url}
                  as="a"
                  target={isExternal ? '_blank' : '_self'}
                >
                  {linkParagraph.link.text}
                </Button>
              </Link>
            );
          })}
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Links;
